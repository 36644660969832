import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import useAppConfig from '../app/app-hook';
import {
  useDemoMeQuery,
  useLogoutMutation,
  UserMeFragment,
} from '../common-lib/generated/graphql';
import { setAccessToken } from '../graphql/client';
import { AuthState } from './auth-state';

export default function useDemoAuth() {
  const client = useApolloClient();
  const navigate = useNavigate();

  const [appConfig, setAppConfig] = useAppConfig();
  const { appInit, authInit } = appConfig || {};
  const [authState, setAuthState] = useRecoilState(AuthState);

  const [logoutMutation] = useLogoutMutation();

  const { loading, data } = useDemoMeQuery({
    fetchPolicy: 'cache-first',
    skip: !!authState?.jwt,
  });

  // app init when project had been fetched
  useEffect(() => {
    async function runAppInit() {
      if (authInit && !appInit) {
        setAppConfig({ authInit, appInit: true });
      }
    }

    runAppInit();
  }, [setAppConfig, authInit, appInit]);

  const handleLogin = async (user?: UserMeFragment, jwt?: string | null) => {
    setAccessToken(jwt);

    setAuthState({
      jwt,
      user,
    });
  };

  // auth initalize
  useEffect(() => {
    async function runAuthInit() {
      if (!authInit && !loading) {
        if (data?.demoMe?.user) {
          await handleLogin(data.demoMe.user, data.demoMe.jwt);
        }

        setAppConfig({ appInit, authInit: true });
      }
    }
    runAuthInit();
  }, [setAuthState, authInit, data, loading]);

  const logout = useCallback(async () => {
    await logoutMutation();

    setAppConfig(null);
    setAuthState(null);
    setAccessToken(null);
    client.clearStore();
  }, [client, setAuthState, setAccessToken, logoutMutation]);

  return [
    { authInit, appInit, loading, data, user: authState?.user, authState },
    { handleLogin, logout, navigate, setAuthState, setAppConfig },
  ] as const;
}
