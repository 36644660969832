import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './landing/LandingPage';
import Layout from './Layout';

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<LandingPage />} />
        </Route>
        <Route path='*' element={<div />} />
      </Routes>
    </Router>
  );
}
