import { ReactNode } from 'react';
import { Outlet } from 'react-router';
import Footer from './Footer';

import logoS from './assets/logo-square.svg';

function Layout({ children }: { children?: ReactNode }) {
  return (
    <div className='px-5 container m-auto'>
      <header className='py-4 flex flex-center-y gap-3 text-center'>
        <img src={logoS} className='w-14 rounded-lg mx-auto' />
        {/* <span>Omni API</span> */}
      </header>
      {children || <Outlet />}
      <Footer />
    </div>
  );
}

export default Layout;
