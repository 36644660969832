export function getEnvs() {
  const isProdRuntime = process.env.REACT_APP_ENV === 'production';

  const envs = {
    BackendUrl: `${process.env.REACT_APP_BACKEND_BASE}/graphql`,
    backendbaseUrl: process.env.REACT_APP_BACKEND_BASE,
    isProdRuntime,
    isLandingWeb: !!process.env.REACT_APP_LANDING,
    isDemo: process.env.REACT_APP_DEMO === '1',
    isProd: process.env.NODE_ENV === 'production',
  };

  // eslint-disable-next-line
  // console.log('envs', envs);

  return envs;
}

export default getEnvs();
