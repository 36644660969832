export default function Footer() {
  return (
    <footer className='my-8 rounded-2xl footer p-10 bg-base-300 text-base-content'>
      <div>
        <p>
          Omni API
          <br />
          built with ❤️
        </p>
      </div>
      <div>
        <span className='footer-title'>Company</span>
        <a className='link link-hover'>About us</a>
        <a className='link link-hover'>Contact</a>
      </div>
      <div>
        <a className='link link-hover'>Terms of use</a>
        <a className='link link-hover'>Privacy policy</a>
        <a className='link link-hover'>Cookie policy</a>
      </div>
    </footer>
  );
}
