import { atom, useRecoilState } from 'recoil';

const AppConfigState = atom<{ authInit?: boolean; appInit?: boolean } | null>({
  key: 'AppConfigState',
  default: { authInit: false, appInit: false },
});

export default function useAppConfig() {
  const [appConfig, setAppConfig] = useRecoilState(AppConfigState);

  return [appConfig, setAppConfig] as const;
}

export const DemoUploadInvoiceNumbers = atom<string[]>({
  key: 'DemoUploadInvoiceNumbers',
  default: [],
});

export function useDemoUploadState() {
  const [demoUploadInvNums, setDemoUploadInvNums] = useRecoilState(
    DemoUploadInvoiceNumbers
  );

  return [demoUploadInvNums, setDemoUploadInvNums] as const;
}
