import {
  ApolloClient,
  ApolloLink,
  from,
  // createHttpLink,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { AuthState } from '../auth/auth-state';
import { RefreshTokenDocument } from '../common-lib/generated/graphql';
import envs from '../common/envs';
import { setRecoilState } from '../utils/recoil-utils';

const httpLink = createUploadLink({
  // Server URL (must be absolute)
  uri: envs.BackendUrl,
  // headers: { 'Apollo-Require-Preflight': 'true' },
  fetchOptions: {
    credentials: 'include',
  },
});

const errorLinkRefreshToken = onError(() => {
  // eslint-disable-next-line
  console.log('error handle for refresh token');

  // refresh token error
  window.location.href = '/';
});

export function createClient() {
  const client = new ApolloClient<NormalizedCacheObject>({
    link: from([errorLinkRefreshToken, httpLink]),
    cache: new InMemoryCache(),
  });

  return client;
}

let accessToken: undefined | string | null = null;

export function setAccessToken(value: typeof accessToken) {
  accessToken = value;
}

export function getAccessToken() {
  return accessToken;
}

const errorLink = onError(
  // eslint-disable-next-line
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError) {
      // @ts-ignore
      const { statusCode } = networkError;

      if (statusCode === 401) {
        const client = createClient();
        return fromPromise(
          client
            .mutate({
              mutation: RefreshTokenDocument,
            })
            .then((r) => {
              setRecoilState(AuthState, () => r.data.refreshToken);
              accessToken = r.data.refreshToken?.jwt;
              return forward(operation);
            })
        ).flatMap(() => {
          return forward(operation);
        });
      }

      // eslint-disable-next-line
      console.log(`[Network error]: ${networkError}`);
    }

    return undefined;
  }
);

const createAuthMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const at = getAccessToken();
    // eslint-disable-next-line
    // console.log('accessToken middle', at);

    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          // 'Apollo-Require-Preflight': 'true',
          /* 'X-Apollo-Operation-Name': 'name', */
          /* 'Access-Control-Allow-Origin': '*', */
          ...(at
            ? {
                authorization: `Bearer ${at}`,
              }
            : null),
        },
      };
    });

    return forward(operation);
  });
};

export function useClient() {
  const [authState] = useRecoilState(AuthState);

  useEffect(() => {
    setAccessToken(authState?.jwt);
  }, [authState]);

  const client = new ApolloClient<NormalizedCacheObject>({
    link: from([errorLink, createAuthMiddleware(), httpLink]),
    cache: new InMemoryCache(),
    // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
    // defaultOptions: {
    //   query: {
    //     fetchPolicy: 'network-only',
    //   },
    // },
  });

  return client;
}
