// import { ValueBlock } from '../testing-types';

import { Box } from '../landing/types';

export function dollarStrToFloat(str?: string): number {
  if (!str) {
    return 0;
  }

  return Number(str.replace(/[^0-9.-]+/g, ''));
}

// FIX for JS number precision
// https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
export function precisionRoundMod(number: number, precision = 2) {
  // eslint-disable-next-line
  const factor = Math.pow(10, precision);
  const n = precision < 0 ? number : 0.01 / factor + number;
  return Math.round(n * factor) / factor;
}

const ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];

/**
 * format big numbers into human readables
 * @param n input number
 * @returns formatted value as 3.4K, 12.9M
 */
export function formatNumber(n: number, precision = 1) {
  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      return (
        // eslint-disable-next-line
        (n / ranges[i].divider).toFixed(precision).toString() +
        `${ranges[i].suffix}`
      );
    }
  }
  return n.toString();
}

export function formatPercent(number?: number) {
  const num = (number || 0) * 100;
  return `${num}%`;
}

export function addPaddingAndFormatBox(
  { width, height }: { width: number; height: number },
  box: Box,
  {
    padding,
  }: {
    padding?: {
      x: number;
      y: number;
    };
  } = {}
) {
  if (padding?.y && padding?.x) {
    return {
      top: formatPercent(box.top / height - padding.y),
      height: formatPercent(box.h / height + padding.y * 2),
      left: formatPercent(box.left / width - padding.x),
      width: formatPercent(box.w / width + padding.x * 2),
    };
  }
  return {
    top: box.top,
    left: box.left,
    width: box.w,
    height: box.h,
  };
}
