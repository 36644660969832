import { atom } from 'recoil';
import { UsersPermissionsMe } from '../common-lib/generated/graphql';

export type AuthStateType = {
  jwt?: string | null;
  user?: UsersPermissionsMe | null;
} | null;

export const AuthState = atom<AuthStateType>({
  key: 'AuthState',
  default: null,
});
