import {
  ReactZoomPanPinchHandlers,
  ReactZoomPanPinchState,
  TransformComponent,
  TransformWrapper,
} from '@pronestor/react-zoom-pan-pinch';
import classNames from 'classnames';
import { memo, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdClose, MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  FileIndexFragment,
  useFileItemQuery,
} from '../common-lib/generated/graphql';
import Loading from '../common/loading/Loading';
import Modal from '../common/modal/Modal';
import { getMediaUrl } from '../utils/image';
import { addPaddingAndFormatBox } from '../utils/numbers';
import { FileIndexBoxInfo, FileItemMetadata } from './types';

function FileViewModal({
  openFileIndex,
  onClose,
}: {
  openFileIndex: FileIndexFragment;
  onClose: () => void;
}) {
  const fileItemId = openFileIndex?.attributes?.fileItem?.data?.id || '';
  const fileIndexUuid = openFileIndex?.attributes?.uuid || '';
  const boxInfo = openFileIndex?.attributes?.boxInfo as FileIndexBoxInfo;

  const { data, loading: loadingFileItem } = useFileItemQuery({
    skip: !fileItemId,
    variables: { id: fileItemId },
  });

  let resetZoomRef: ReactZoomPanPinchHandlers['resetTransform'] | undefined;
  let zoomToElementRef: ReactZoomPanPinchHandlers['zoomToElement'] | undefined;
  //   let centerViewRef: ReactZoomPanPinchHandlers['centerView'] | undefined;

  const [selectedBoxUuid, setSelectedBoxUuid] = useState<
    string | undefined | null
  >(fileIndexUuid);
  const [currPageNum, setCurrPageNum] = useState(boxInfo?.pageNum || 1);
  const [zoomState, setZoomState] = useState<ReactZoomPanPinchState | null>(
    null
  );
  const [loadedPages, setLoadedPages] = useState<number[]>([]);

  const fileItem = data?.fileItem?.data;
  const metadata: FileItemMetadata | undefined = fileItem?.attributes?.metadata;
  const pages = metadata?.pages;
  const currPage = pages?.[currPageNum - 1];
  const paragraphs = currPage?.paragraphs || [];
  const pageMetadata = currPage?.metadata;

  const selectedParag = useMemo(() => {
    return paragraphs.find((p) => p.uuid === selectedBoxUuid);
  }, [paragraphs, selectedBoxUuid]);

  const currentPageImageLoaded = useMemo(() => {
    return loadedPages.includes(currPageNum || -1);
  }, [currPageNum, loadedPages]);

  // zoom into where the value is
  useEffect(() => {
    if (zoomToElementRef && currentPageImageLoaded) {
      const boxKey = `box=${fileIndexUuid}`;

      setTimeout(() => {
        if (zoomToElementRef) {
          zoomToElementRef(boxKey, 3);
        }
      }, 200);
    }
  }, [currentPageImageLoaded, fileIndexUuid, zoomToElementRef]);

  // init load image center
  // useEffect(() => {
  //   if (centerViewRef && !loadingFileItem) {
  //     setTimeout(() => {
  //       if (centerViewRef) {
  //         centerViewRef();
  //       }
  //     }, 100);
  //   }
  // }, [centerViewRef, loadingFileItem]);

  const loading = !fileItem || loadingFileItem;

  return (
    <Modal
      containerCls='w-full'
      open
      className='w-full max-w-[98vw] h-full max-h-[98vh] bg-base-200 p-0 overscroll-contain'
      onClickBackdrop={() => {
        onClose();
      }}
    >
      {loading && (
        <div className='flex flex-1 relative flex-center h-full'>
          <Loading radius={40} stroke={5} />
        </div>
      )}

      {!loading && (
        <div className='flex flex-1 relative h-full'>
          <div className='flex flex-1 justify-items-stretch'>
            <div
              className={classNames(
                'flex-none h-full relative bg-black',
                'w-[60px] md:w-[100px]'
              )}
            >
              <ul className='p-2 flex flex-col gap-3'>
                {metadata?.pages?.map((page, idx) => {
                  const selected = page.pageNum === currPageNum;

                  return (
                    <li
                      key={idx}
                      className={classNames(
                        'border-4 p-1',
                        selected ? 'border-white' : 'border-black'
                      )}
                    >
                      <div
                        className='bg-white cursor-pointer h-full'
                        onClick={() => {
                          setCurrPageNum(page.pageNum);

                          if (resetZoomRef) {
                            resetZoomRef();
                          }
                        }}
                      >
                        <div className='h-full'>
                          <img
                            src={getMediaUrl(
                              `/api/file/image/${fileItem?.id}/${page.pageNum}/page?size=thumb`
                            )}
                            className='w-full'
                          />
                        </div>
                        <div className='relative p-1'>
                          <span
                            className={classNames(
                              'absolute bottom-2 rounded border-2 bg-white',
                              'p-[1px] md:px-2 md:py-1 right-0 md:right-2',
                              'text-xs md:text-md'
                            )}
                          >
                            {page.pageNum}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='flex-1 h-full'>
              <TransformWrapper
                doubleClick={{ disabled: true }}
                onZoom={(ref) => {
                  setZoomState(ref.state);
                }}
                wheel={{
                  step: 0.03,
                }}
              >
                {({ zoomToElement, resetTransform }) => {
                  resetZoomRef = resetTransform;
                  zoomToElementRef = zoomToElement;

                  return (
                    <TransformComponent>
                      <div className='p-5 w-full'>
                        <div className='relative'>
                          {/* pdf page image big */}
                          <img
                            onLoad={() => {
                              if (currPage?.pageNum) {
                                setLoadedPages([
                                  ...loadedPages,
                                  currPage?.pageNum,
                                ]);
                              }
                            }}
                            src={getMediaUrl(
                              `/api/file/image/${fileItem?.id}/${currPage?.pageNum}/page`
                            )}
                          />

                          {paragraphs.map((para) => {
                            const { box, uuid } = para;

                            if (
                              !pageMetadata ||
                              !box.top ||
                              !box.left ||
                              !box.h ||
                              !box.w
                            ) {
                              return null;
                            }

                            const boxKey = `box=${uuid}`;

                            const selected = selectedBoxUuid === uuid;

                            return (
                              <div
                                key={uuid}
                                id={boxKey}
                                className={classNames(
                                  'absolute border-[0.5pt] cursor-pointer border-red-500 ',
                                  selected
                                    ? 'border-opacity-60'
                                    : 'border-dashed border-opacity-40'
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  zoomToElement(boxKey, zoomState?.scale || 2);
                                  setSelectedBoxUuid(uuid);
                                }}
                                style={addPaddingAndFormatBox(
                                  pageMetadata,
                                  box,
                                  {
                                    padding: {
                                      x: 0.002,
                                      y: 0.002,
                                    },
                                  }
                                )}
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                    </TransformComponent>
                  );
                }}
              </TransformWrapper>
            </div>
          </div>
          <div className='absolute w-full h-0 overflow-visible'>
            <div className='absolute flex flex-1 justify-end items-end right-0'>
              <MdClose
                className='w-10 h-10 opacity-60 m-2'
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className='absolute flex flex-1 justify-end items-end right-0'>
              <MdClose
                className='relative w-10 h-10 opacity-60 m-2 text-white top-[2px]'
                onClick={() => {
                  onClose();
                }}
              />
            </div>
          </div>
          {selectedParag && (
            <div
              className={classNames(
                'absolute w-full pr-5 bottom-5',
                'pl-20 md:pl-32'
              )}
            >
              <div className='flex flex-1 rounded p-5 bg-primary bg-opacity-90 text-white font-bold gap-2 items-center'>
                <CopyToClipboard
                  text={selectedParag.text || ''}
                  onCopy={() => {
                    toast.info('copied', {
                      autoClose: 700,
                    });
                  }}
                >
                  <div className='flex flex-1 flex-center-y gap-4'>
                    <span className='text-lg'>{selectedParag.text}</span>

                    <MdContentCopy className='flex-shrink-0  w-7 h-7' />
                  </div>
                </CopyToClipboard>

                <span
                  className='flex-none cursor-pointer'
                  onClick={() => {
                    setSelectedBoxUuid(null);
                  }}
                >
                  <MdClose className='flex-shrink-0  w-7 h-7' />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}

export default memo(FileViewModal);
