import { startsWith } from 'lodash';
import envs from '../common/envs';

export type ImgSize = 'small' | 'thumb' | 'large';

export function getMediaUrl(url?: string | null) {
  if (!url) {
    return undefined;
  }

  if (startsWith(url, 'http')) {
    return url;
  }
  return `${envs.backendbaseUrl}${url}`;
}
