import classNames from 'classnames';
import { isEmpty, size } from 'lodash';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BsFillFilePdfFill } from 'react-icons/bs';
import { MdContentCopy, MdImage, MdInfo, MdUpload } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { useFilePicker } from 'use-file-picker';
import illur from '../assets/illur.png';
import useDemoAuth from '../auth/use-demo-auth';
import {
  FileIndexFragment,
  useFileIndicesLazyQuery,
  useFileItemsLazyQuery,
  useProcessMutation,
} from '../common-lib/generated/graphql';
import Loading from '../common/loading/Loading';
import { getMediaUrl } from '../utils/image';
import FileViewModal from './FileViewModal';

const maxItemLimit = 10;

export default function LandingPage() {
  const [{ user, authInit, loading: authLoading }] = useDemoAuth();
  const userId = user?.id || '';

  const [sampleType, setSampleType] = useState<'sample' | 'upload' | null>(
    'sample'
  );

  const [fetchFileIndices, { data, loading }] = useFileIndicesLazyQuery();
  const [searchInput, setSearchInput] = useState('');
  const [searchKey] = useDebounce(searchInput, 800);

  const [openFileIndex, setOpenFileIndex] = useState<FileIndexFragment | null>(
    null
  );

  const [fetchFileItems, { data: dataFileItems, loading: loadingFileItems }] =
    useFileItemsLazyQuery();

  const fileItems = dataFileItems?.fileItems?.data;
  const fileItemsSize = size(fileItems) || 0;
  const fileCountQuota = maxItemLimit - fileItemsSize;

  // file upload
  const [openFileSelector, { plainFiles, clear, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.pdf'],
    multiple: true,
    maxFileSize: 5,
  });

  const [process, { loading: uploading }] = useProcessMutation({
    onCompleted() {
      clear();
    },
    onError(err) {
      toast.error(err.message);
      clear();
    },
    refetchQueries: ['FileItems', 'FileIndices'],
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      toast.error('Maximum file size is 5M');
      clear();
    }
  }, [errors]);

  useEffect(() => {
    if (uploading) {
      return;
    }

    const filesToProcess = plainFiles.slice(0, fileCountQuota);
    if (filesToProcess.length < plainFiles.length) {
      toast.warn('Max file count reached');
    }

    filesToProcess.forEach((file) => {
      process({
        variables: {
          file,
        },
      });
    });
  }, [uploading, fileCountQuota, plainFiles]);

  // fetch file items based on sample selection
  useEffect(() => {
    if (!userId || loadingFileItems) {
      return;
    }

    fetchFileItems({
      variables: {
        filters: {
          owner: {
            id: {
              eq: sampleType === 'sample' ? 'demo' : userId,
            },
          },
        },
      },
    });
  }, [userId, sampleType]);

  // fetch search results
  useEffect(() => {
    if (!authInit) {
      return;
    }

    if (sampleType === 'upload') {
      const myItemIds = fileItems
        ?.map((item) => {
          return item.id;
        })
        .filter((v): v is string => !!v);

      if (myItemIds && !isEmpty(myItemIds)) {
        fetchFileIndices({
          variables: {
            filters: {
              fileItem: {
                id: {
                  in: myItemIds,
                },
              },
              text: {
                containsi: searchKey,
              },
              owner: {
                id: {
                  eq: user?.id,
                },
              },
            },
          },
        });
      }
    } else {
      fetchFileIndices({
        variables: {
          filters: {
            fileItem: {
              id: {
                eq: 'demo',
              },
            },
            text: {
              containsi: searchKey,
            },
            owner: {
              id: {
                eq: user?.id,
              },
            },
          },
        },
      });
    }
  }, [fileItems, sampleType, authInit, searchKey]);

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    if (openFileIndex) {
      if (body && body?.[0]) {
        body[0].className = 'overflow-hidden';
      }
    } else {
      body[0].className = '';
    }

    return () => {
      body[0].className = '';
    };
  }, [openFileIndex]);

  const fileIndices = data?.fileIndices?.data;

  const initLoading = loading || !fileIndices;

  if (!authInit || authLoading) {
    <div className='flex flex-1 flex-center'>
      <Loading />
    </div>;
  }

  return (
    <div
      className={classNames('py-10', openFileIndex ? 'overflow-hidden' : '')}
    >
      <div className='flex flex-col gap-7 max-w-xl mx-auto'>
        <div className='select-none'>
          <h4 className='text-4xl font-extralight text-center w-full'>
            Search your PDFs like a pro
          </h4>
          <img src={illur} className='pointer-events-none mx-auto' />
          <h4 className='text-2xl font-light w-full text-center'>
            Upload them and start searching!
          </h4>
        </div>
        <div className='flex flex-row'>
          <div className='flex-1 flex-center'>
            <div
              className={classNames(
                'p-5 w-full h-full border-2 cursor-pointer flex-center rounded-lg',
                sampleType === 'sample'
                  ? 'bg-primary text-white'
                  : ' opacity-60'
              )}
              onClick={() => {
                setSampleType('sample');
              }}
            >
              <span className='font-bold'>Try samples</span>
            </div>
          </div>
          <div className='self-stretch px-5'>
            <div className='h-full border-r'></div>
          </div>
          <div className='flex-1'>
            <div
              onClick={() => {
                setSampleType('upload');
              }}
              className={classNames(
                'p-5 w-full h-full border-4 border-dashed cursor-pointer flex-center rounded-lg',
                sampleType === 'upload'
                  ? 'bg-primary text-white'
                  : ' opacity-60'
              )}
            >
              <span className='font-bold'>Upload PDFs or Images</span>
            </div>
          </div>
        </div>
        {sampleType === 'upload' && (
          <div className=''>
            <h3 className='mb-1'>My files</h3>
            <div className='flex flex-wrap'>
              {fileItems?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className='flex flex-nowrap flex-center-y gap-2 rounded bg-primary bg-opacity-20 p-2 mr-1 mb-1'
                  >
                    <span>
                      {item.attributes?.type === 'image' && (
                        <MdImage className='w-5 h-5' />
                      )}
                      {item.attributes?.type === 'pdf' && <BsFillFilePdfFill />}
                    </span>
                    <span className='text-xs'>{item.attributes?.fileName}</span>
                  </div>
                );
              })}
              <div
                className='flex flex-nowrap flex-center-y gap-2 rounded bg-primary bg-opacity-10 p-2 mr-1 mb-1 flex-center-y cursor-pointer'
                onClick={() => {
                  if (uploading) return;

                  if (fileItems && fileItems?.length > maxItemLimit) {
                    toast.warn(
                      'This is a prototype. So please contact me if you want to process more'
                    );
                    return;
                  }

                  openFileSelector();
                }}
              >
                {uploading ? (
                  <Loading />
                ) : (
                  <MdUpload className='w-5 h-5 text-primary' />
                )}
                <span className='text-primary'>Upload</span>
                <div
                  className='tooltip'
                  data-tip={`You can upload ${fileCountQuota} more file(s). Max for now is ${maxItemLimit}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <MdInfo />
                </div>
              </div>
            </div>
          </div>
        )}
        {sampleType === 'sample' && (
          <div className=''>
            <h3 className='mb-1'>Samples</h3>
            <div className='flex flex-wrap'>
              {fileItems?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className='flex flex-nowrap flex-center-y gap-2 rounded bg-primary bg-opacity-20 p-2 mr-1 mb-1'
                  >
                    <span>
                      {item.attributes?.type === 'image' && (
                        <MdImage className='w-5 h-5' />
                      )}
                      {item.attributes?.type === 'pdf' && <BsFillFilePdfFill />}
                    </span>
                    <span className='text-xs'>{item.attributes?.fileName}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className='form-control'>
          <div className='input-group'>
            <input
              type='text'
              placeholder='Search…'
              className='input input-bordered rounded-full w-full'
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <button className='btn btn-primary btn-square'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                />
              </svg>
            </button>
          </div>
        </div>

        {initLoading && (
          <div className='flex-center p-10'>
            <Loading />
          </div>
        )}
        {!initLoading && isEmpty(fileIndices) && (
          <div className='flex-center p-10'>No matches</div>
        )}
        {!initLoading && (
          <div className='flex flex-col gap-3'>
            {fileIndices?.map((fileIndex) => {
              return (
                <div
                  key={fileIndex.id}
                  className='p-3 border rounded-lg flex gap-2 cursor-pointer'
                  onClick={() => {
                    // open view
                    if (fileIndex.attributes?.fileItem?.data?.id) {
                      setOpenFileIndex(fileIndex);
                    }
                  }}
                >
                  <div className='flex-1'>
                    <div className='font-semibold flex-center-y gap-1'>
                      {fileIndex.attributes?.text}

                      <CopyToClipboard
                        text={fileIndex.attributes?.text || ''}
                        onCopy={() => {
                          toast.info('copied', {
                            autoClose: 700,
                          });
                        }}
                      >
                        <MdContentCopy className='flex-shrink-0 opacity-50 w-4 h-4' />
                      </CopyToClipboard>
                    </div>
                    <div className='text-xs'>
                      {
                        fileIndex.attributes?.fileItem?.data?.attributes
                          ?.fileName
                      }{' '}
                      (page: {fileIndex.attributes?.boxInfo?.pageNum})
                    </div>
                  </div>
                  <div className='max-w-[40%]'>
                    <img
                      src={getMediaUrl(
                        `/api/file/box/${fileIndex.attributes?.fileItem?.data?.id}/${fileIndex.attributes?.boxInfo?.pageNum}/${fileIndex.id}`
                      )}
                      className='border border-black border-opacity-5'
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className='flex-1 flex-center py-10 flex-col gap-4'>
          <h4>If you need more than this, </h4>
          <button
            className='btn btn-primary'
            onClick={() => {
              window.open('https://calendly.com/zirho6/sync-up');
            }}
          >
            {'Let me know'}
          </button>
        </div>
      </div>
      {openFileIndex && (
        <FileViewModal
          openFileIndex={openFileIndex}
          onClose={() => {
            setOpenFileIndex(null);
          }}
        />
      )}
    </div>
  );
}
