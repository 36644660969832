/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDOM from 'react-dom';
import { Component, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

const modalRoot = document.getElementById('modal-root') as HTMLElement;

export default class Modal extends Component<
  {
    open?: boolean;
    onClickBackdrop?: () => void;
    containerCls?: string;
    children: ReactNode;
  } & HTMLAttributes<HTMLDivElement>
> {
  el: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot?.removeChild(this.el);
  }

  render() {
    const { containerCls, className, onClickBackdrop, open, children, style } =
      this.props;
    if (!open) {
      return ReactDOM.createPortal(null, this.el);
    }

    return ReactDOM.createPortal(
      <div
        className={classNames('modal modal-open', containerCls)}
        style={style}
        onClick={() => {
          if (onClickBackdrop) {
            onClickBackdrop();
          }
        }}
      >
        <div
          className={classNames('modal-box', className)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>,
      this.el
    );
  }
}
